import EzComponent  from '@frontend/components/EzComponent';
import PropTypes    from 'prop-types';

import { connect }  from 'react-redux';

import { getProductsDataByIds } from '@frontend/actions';

import ProductSlider from '@frontend/components/product-slider/ProductSlider';

import '@frontend/components/product-slider/product-slider-by-ids.less'; 

export class ProductSliderByIds extends EzComponent{
  state = {
    products : []
  };

  static propTypes = {
    productids:                PropTypes.string, // slider Products ids. (example -> productids='["1","2","3","4"]')
    columns:                   PropTypes.number, // Columns slider display
    rows:                      PropTypes.number  // Rows slider display
  }

  static defaultProps = {
    columns: 4,
    rows: 1
  }

  async componentWillMount(){
    const { productids,storeConfiguration,user } = this.props;
    let productIds = JSON.parse(productids.replace(/\\"/g, '"'));
    let variables = {
      productIds,
      customerGroupId: (user && user.id) ? user.group_id : 0,
      storeId: storeConfiguration.storeId,
    }
    let products = await this.props.getProductsDataByIds(this.context,variables);
    this.setState({products});
  }
  render(){
    const {title:sliderTitle,wishlistItems=[],classname='',columns,rows} = this.props;
    const { products=[] } = this.state;
    if (products && products.length!=0){
      return (
        <div className={`product-slider-by-ids ${classname}`}>
          {
            !!sliderTitle &&
            <h3 className="slider-title">
              {sliderTitle}
            </h3>
          }
          <ProductSlider
            className="slider-products"
            products={products}
            wishlistItems={wishlistItems}
            infinite={true}
            isPlaying={false}
            visibleSlides={parseInt(columns)}
            rows={parseInt(rows)}
          />

        </div>
      );
    }else{
      return null;
    }
  }
}

function mapStateToProps({user,storeConfig}){
  return {
    storeConfiguration: storeConfig,
    wishlistItems:      user.wishlist_items,
    user
  }
}
export default connect(mapStateToProps,{getProductsDataByIds})(ProductSliderByIds);
